import styles from './index.module.css'
import cf from '../../../../../common/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import UploadLogo from 'store/uploadLogo'

export default function InvalidPassword(props) {
  // console.log(props)
  const imgMain = props?.pageInfo?.data?.signin?.upload_image
    ? '/shared/api/media/' + props?.pageInfo?.data?.signin?.upload_image
    : 'theme4/login_img.png'

  const BackToForgotPassword = () => {
    // console.log("testttt");
    cf.redirectTo('/login')
    props.setType('Forgotpassword')
  }

  const BackToLogin = () => {
    cf.redirectTo('/login')
  }
  return (
    <>
      <div class={styles.invalidmodal_wrap}>
        <div class={styles.business_logo}>
          <img src={imgMain} alt="login" />
        </div>
        <div className={styles.brand_logo}>
          <img
            src={`${
              '/shared/api/media/' + (UploadLogo?._currentValue?.isLogo ? props?.logo : props?.logo)
            }`}
            alt="logo"
          />
        </div>
        <div className={styles.inv_heading}>Invalid Password Reset Link</div>
        <div className={styles.inv_title}>
          {' '}
          This link is no longer valid.Please try resetting your password again{' '}
        </div>
        <div className={styles.button_wrap}>
          <button className={styles.reset_btn} onClick={() => BackToForgotPassword()}>
            Reset
          </button>
          <button className={styles.cancel_btn} onClick={() => BackToLogin()}>
            Cancel
          </button>
        </div>
      </div>
    </>
  )
}
