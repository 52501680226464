import styles from './index.module.css'
import Image from 'next/image'
import configUrl from '../../../../config/config.json'
import cf from '../../../../common/lib/utils/common'

export default function Theme2(props) {
  let loader
  if (
    !cf.isValueEmpty(props?.props?.home?.data?.site) &&
    !cf.isValueEmpty(props?.props?.home?.data?.site?.default_loader)
  ) {
    loader =
      '/shared/api/media/' +
      props?.props?.home?.data?.site?.id +
      '/' +
      props?.props?.home?.data?.site?.default_loader
  } else {
    loader = './images/default_loder.gif'
  }

  let loaderChecker = loader
  return (
    <div className={`${styles.loader_main} ${styles.centered}`}>
      <img src={loaderChecker} className={styles.loader} alt="" />
    </div>
  )
}
