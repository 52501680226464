import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import styles from './index.module.css'
import IsdCountryDropdown from '../../../IsdCountryDropdown/index'
import { forgotpassword } from '../../../../../common/lib/forgotpassword'
import { login } from '../../../../../common/lib/login'
import { signup } from '../../../../../common/lib/signup'
import ModalPopupComponent from '../../../../../common/components/ModalPopupComponent/ModalPopupComponent'
import modalCss from '../../../../../common/components/ModalPopupComponent/index.module.css'
import CountryDropdownComp from '../../../../../common/components/CountryDropdown/index'
import getCountriesList from '../../../../../common/lib/getCountriesList'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme1/index.module.css'
import { changepassword } from '../../../../../common/lib/changepassword'
import cf from '../../../../../common/lib/utils/common'
import InvalidPassword from './InvalidPassword'
import { tokenExpiry } from '../../../../../common/lib/tokenExpiry'
import { getCityByCountry } from '../../../../lib/myProfile'
import ISDstyles from '../../../../../common/components/IsdCountryDropdown/theme1/index.module.css'
import configUrl from '../../../../../config/config.json'

const errorsList = {
  firstName: {
    ERFN101: 'Please Enter First Name',
  },
  lastName: {
    ERLN101: 'Please Enter Last Name',
  },
  companyName: {
    ERCN101: 'Please Enter Company Name',
  },
  countryCode: {
    ERCC101: 'Please Select The Country Code',
  },
  nationality: {
    ERNT101: 'Please Select Nationality',
  },
  city: {
    ERCT101: 'Please Select City',
  },
  address: {
    ERAD101: 'Please Select Address',
  },
  pincode: {
    ERPN101: 'Please Select Pincode',
  },
  mobileNo: {
    ERMN101: 'Invalid Mobile Number',
    ERMN102: 'Please Enter Mobile Number',
  },
  email: {
    ERE101: 'Please Enter Email Id',
    ERE102: 'Please Enter Valid Email Id',
  },
  password: {
    ERP101:
      'Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character',
    ERP104: 'Please Enter Password',
    ERP105: 'Invalid Password',
  },
  confirmPassword: {
    ERP102: 'Please Re-Enter New Password',
    ERP103: 'Passwords Do Not Match',
  },
  OTP: {
    EROTP101: 'Please Enter OTP',
    EROTP102: 'Please Enter Valid OTP',
  },
  isTermsChecked: {
    ERTC101: 'Please Accept The Terms & Conditions To Proceed',
  },
}

const emailPattern =
  /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
let pwsEx = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,13}$/)
let alphanumericAndSpecialChars = new RegExp(/^[ A-Za-z0-9_@./#&+-]*$/)
// var mobValidation = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export default function Theme1(props) {
  const router = useRouter()
  // console.log(router.query,"query");
  // console.log(props,"login pros page");

  const [type, setType] = useState('Signin')
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [logincred, setLogincred] = useState({ email: '', password: '' })
  const [loginErrors, setLoginErrors] = useState({ email: '', password: '' })
  const [onClickLoginLoader, setOnClickLoginLoader] = useState(false)
  const [forgotPasswordErr, setForgotPasswordErr] = useState('')
  const [loginErrMsg, setLoginErrMsg] = useState('')
  const [signupForm, setSignupForm] = useState({
    firstName: '',
    lastName: '',
    mobileNo: '',
    companyName: '',
    nationality: '',
    email: '',
    address: '',
    pincode: '',
    city: '',
  })
  const [signupFormErr, setSignupFormErr] = useState({
    firstName: '',
    lastName: '',
    mobileNo: '',
    companyName: '',
    nationality: '',
    email: '',
    address: '',
    pincode: '',
    city: '',
  })
  const [originCountries, setOriginCountries] = useState([])
  const [selectedNationality, setSelectedNationality] = useState('')
  const [CountryCode, setCountryCode] = useState('')
  const [passwordObj, setPasswordObj] = useState({ newPassword: '', confirmPassword: '' })
  const [passwordObjErr, setPasswordObjErr] = useState({ newPassword: '', confirmPassword: '' })
  const [passwordUpdate, setPasswordUpdate] = useState(false)
  const [changePasswordAPIErr, setChangePasswordAPIErr] = useState('')
  const [resettokenExpiry, setResetTokenExpiry] = useState(false)
  const [cityList, setCityList] = useState([])
  const [selectedcity, setselectedCity] = useState('')
  const [CityCode, setCityCode] = useState('')
  const [state, setState] = useState({
    searchCountryText: '',
    demoForm: { countryCode: '' },
    demoFormErr: { countryCode: '' },
    selectedCountry: '',
  })

  useEffect(() => {
    const cityListVar = []
    getCountriesList().then((resp) => {
      let countryList = []
      if (resp?.data?.length > 0) {
        resp?.data.map((item) => {
          countryList.push({
            ...item,
            value: item.contry_name,
            isdCode: item.contry_isd,
            countryCode: item.contry_code,
            flag: item.contry_flag,
            countryFlag: item.contry_flag,
            countryName: item.contry_name,
          })
        })
      }
      setOriginCountries(countryList)
    })

    // if (cityList.length === 0) {
    // let data = {
    //         "country_name": CountryCode ? CountryCode : ""

    // }
    // getCityByCountry(data).then(res => {
    //     console.log(res,"res");
    // 	if (res && res.data && res.data.length > 0) {
    // 		res.data.map((fact, index) => {
    // 			cityListVar.push({
    // 				value: fact.city_name,
    // 				countryId: index,
    // 				countryName: fact.city_name,
    // 			})
    // 		})
    // 		setCityList([...cityListVar])
    // 	}
    // })
    // }
    if (
      router.query &&
      router.query.resettoken &&
      router.query.resettoken !== '' &&
      router.query.resettoken !== null &&
      router.query.type.trim() === 'B2B'
    ) {
      let response = {
        resettoken: router?.query?.resettoken,
        site_id: props?.home?.data?.site.id,
      }
      tokenExpiry(response).then((resp) => {
        // console.log(resp);
        if (resp && resp.data.status) {
          setResetTokenExpiry(false)
        } else {
          setResetTokenExpiry(true)
        }
      })
    }
  }, [])

  //Forgot pass
  const onChangeForgotPassEmail = (e) => {
    setForgotPasswordEmail(e.target.value)
    setForgotPasswordErr('')
  }

  const onClickForgotSubimitBtn = () => {
    setForgotPasswordErr(
      forgotPasswordEmail === ''
        ? errorsList.email.ERE101
        : forgotPasswordEmail.match(emailPattern) !== null
          ? ''
          : errorsList.email.ERE102
    )
    if (forgotPasswordEmail !== '' && forgotPasswordEmail.match(emailPattern) !== null) {
      //api call
      let forgotPassReq = {
        email: forgotPasswordEmail,
      }
      forgotpassword(forgotPassReq).then((res) => {
        // console.log('forgot pass in common', res);
        if (res && res.status === true && res.message && res.message !== '') {
          setIsOpen(true)
          setForgotPasswordErr('')
        } else {
          setIsOpen(false)
          setForgotPasswordErr(res.message)
        }
      })
    } else {
      setIsOpen(false)
    }
  }

  // Login Functinality Starts
  const onChangeLoginEmail = (e) => {
    setLogincred({ ...logincred, email: e.target.value })
    setLoginErrors({ ...loginErrors, email: '' })
  }
  const onChangeLoginPassword = (e) => {
    let spaceEX = new RegExp(/(^\S*$)/)
    let confpwsEx = new RegExp(/^(?=.{0,13}$).*/)
    if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
      setLogincred({ ...logincred, password: e.target.value })
      setLoginErrors({ ...loginErrors, password: '' })
    } else {
      setLoginErrors({ ...loginErrors, password: 'Please Enter Valid Password' })
    }
  }

  //login in button
  const onClickLoginBtn = () => {
    setLoginErrors({
      ...loginErrors,
      email: logincred.email === '' ? errorsList.email.ERE101 : '',
      password:
        logincred.password === ''
          ? errorsList.password.ERP104
          : logincred.password.match(pwsEx) !== null
            ? ''
            : errorsList.password.ERP105,
    })
    setLoginErrMsg('')
    if (
      logincred.email !== '' &&
      logincred.password !== '' &&
      logincred.password.match(pwsEx) !== null
    ) {
      let loginReq = {
        email: logincred.email,
        password: logincred.password,
        // "type": this.state.toggleAgentLogin ? 'agent' : undefined
      }
      setOnClickLoginLoader(true)
      login(loginReq).then((responseJSON) => {
        console.log('responseJSON', responseJSON)
        if (
          responseJSON.status === true &&
          responseJSON.accessToken !== '' &&
          responseJSON.accessToken !== undefined &&
          responseJSON.accessToken !== null
        ) {
          // commonFunction.redirectTo('/')
          window.location.href = '/'
        } else {
          setLoginErrMsg(typeof responseJSON.message === 'string' ? responseJSON.message : '')
          setOnClickLoginLoader(false)
        }
      })
    }
  }
  //Login Func Ended

  const onClickNationalityHandler = (params) => {
    console.log(params, 'natanality')
    setSelectedNationality(params)
    setCountryCode(params.value)
    setSignupForm({
      ...signupForm,
      nationality: {
        value: params.value,
        countryFlag: params.countryFlag,
        countryName: params.countryName,
      },
    })
    setSignupFormErr({ ...signupFormErr, nationality: '' })
    if (params.value) {
      const cityListVar = []
      let data = {
        country_name: params.value ? params.value : '',
      }
      getCityByCountry(data).then((res) => {
        console.log(res, 'res')
        if (res && res.data && res.data.length > 0) {
          res.data.map((fact, index) => {
            cityListVar.push({
              value: fact.city_name,
              countryId: index,
              countryName: fact.city_name,
            })
          })
          setCityList([...cityListVar])
          setCityCode('')
          setselectedCity({
            countryId: 0,
            countryName: '',
            value: '',
          })
        }
      })
    }
  }

  const onChangeInputNationality = (params) => {
    setSelectedNationality({ value: params.target.value })
    setCountryCode(params.target.value)
    setSignupForm({ ...signupForm, nationality: params.target.value })
  }

  function CityCodeClickHandler(params) {
    console.log('CityCodeClickHandler', params)
    setselectedCity(params)
    setCityCode(params.value)
    setSignupForm({
      ...signupForm,
      city: { value: params.value, countryId: params.countryId, countryName: params.countryName },
    })
    setSignupFormErr({ ...signupFormErr, city: '' })
  }

  function onChangeInputCityCode(params) {
    setCityCode(params.target.value)
    setselectedCity({})
    setSignupForm({ ...signupForm, city: params.target.value })
  }

  // signup

  const onChangeSignFName = (e) => {
    let uppercase = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    if (e.target.value.charCodeAt(0) !== 32 && e.target.value.match(alphanumericAndSpecialChars)) {
      setSignupForm({ ...signupForm, firstName: uppercase })
      setSignupFormErr({ ...signupFormErr, firstName: '' })
    }
  }

  const onChangeSignLName = (e) => {
    let uppercase = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
    if (e.target.value.charCodeAt(0) !== 32 && e.target.value.match(alphanumericAndSpecialChars)) {
      setSignupForm({ ...signupForm, lastName: uppercase })
      setSignupFormErr({ ...signupFormErr, lastName: '' })
    }
  }

  const onChangeSignMobile = (e) => {
    setSignupForm({ ...signupForm, mobileNo: e.target.value.replace(/[^0-9]+/g, '') })
    setSignupFormErr({ ...signupFormErr, mobileNo: '' })
  }

  const onChangeSignCompanyName = (e) => {
    setSignupForm({ ...signupForm, companyName: e.target.value })
    setSignupFormErr({ ...signupFormErr, companyName: '' })
  }

  const onChangeSignEmail = (e) => {
    setSignupForm({ ...signupForm, email: e.target.value.replace(/\s/g, '') })
    setSignupFormErr({ ...signupFormErr, email: '' })
  }

  const onChangeSignAddress = (e) => {
    setSignupForm({ ...signupForm, address: e.target.value })
    setSignupFormErr({ ...signupFormErr, address: '' })
  }
  const onChangePinCode = (e) => {
    setSignupForm({ ...signupForm, pincode: e.target.value.replace(/[^0-9]+/g, '') })
    setSignupFormErr({ ...signupFormErr, pincode: '' })
  }

  const onSignUpBtn = () => {
    setSignupFormErr({
      ...signupFormErr,
      firstName:
        signupForm.firstName === '' || signupForm.firstName === undefined
          ? errorsList.firstName.ERFN101
          : '',
      lastName:
        signupForm.lastName === '' || signupForm.lastName === undefined
          ? errorsList.lastName.ERLN101
          : '',
      mobileNo:
        signupForm.mobileNo === '' || signupForm.mobileNo === undefined
          ? errorsList.mobileNo.ERMN102
          : signupForm.mobileNo.length > 3
            ? ''
            : errorsList.mobileNo.ERMN101,
      companyName:
        signupForm.companyName === '' || signupForm.companyName === undefined
          ? errorsList.companyName.ERCN101
          : '',
      email:
        signupForm.email === '' || signupForm.email === undefined
          ? errorsList.email.ERE101
          : signupForm.email.match(emailPattern) !== null
            ? ''
            : errorsList.email.ERE102,
      nationality:
        signupForm.nationality === '' || signupForm.nationality === undefined
          ? errorsList.nationality.ERNT101
          : '',
      city: signupForm.city === '' || signupForm.city === undefined ? errorsList.city.ERCT101 : '',
      address:
        signupForm.address === '' || signupForm.address === undefined
          ? errorsList.address.ERAD101
          : '',
      pincode:
        signupForm.pincode === '' || signupForm.pincode === undefined
          ? errorsList.pincode.ERPN101
          : '',
    })
    if (
      signupForm.email !== '' &&
      signupForm.firstName !== '' &&
      signupForm.mobileNo !== '' &&
      signupForm.mobileNo.length > 3 &&
      signupForm.lastName !== '' &&
      signupForm.companyName !== '' &&
      signupForm.address !== '' &&
      signupForm.pincode !== '' &&
      signupForm.nationality !== '' &&
      signupForm.nationality !== undefined &&
      signupForm.city !== '' &&
      signupForm.city !== undefined
    ) {
      let signupReq = {
        first_name: signupForm.firstName,
        last_name: signupForm.lastName,
        company_name: signupForm.companyName,
        mobile_number: signupForm.mobileNo,
        email: signupForm.email,
        country: signupForm.nationality, // changed the key nationality ->country
        city: signupForm.city,
        address: signupForm.address,
        pincode: signupForm.pincode,
        type: 'B2B',
      }
      // console.log(signupReq,"signupReq");
      setOnClickLoginLoader(true)
      signup(signupReq).then((res) => {
        //  console.log(res,"singup res");
        if (res && res.status && res.message !== '') {
          setSignupFormErr({ email: '' })
          setIsOpen(true)
          setOnClickLoginLoader(false)
        } else {
          setSignupFormErr({
            email:
              typeof res.message === 'string'
                ? res.message
                : res.message && res.message.length > 0
                  ? res.message[0].msg
                  : 'some thing went wrong',
          })
          setOnClickLoginLoader(false)
        }
      })
    }
  }

  const onChangeNewPassword = (e) => {
    setPasswordObj({ ...passwordObj, newPassword: e.target.value })
    setPasswordObjErr({ ...passwordObjErr, newPassword: '' })
  }

  const onChangeConfirmPassword = (e) => {
    setPasswordObj({ ...passwordObj, confirmPassword: e.target.value })
    setPasswordObjErr({ ...passwordObjErr, confirmPassword: '' })
  }

  const onClickConfirmbtn = () => {
    setPasswordObjErr({
      ...passwordObjErr,
      newPassword:
        passwordObj.newPassword === ''
          ? errorsList.password.ERP104
          : passwordObj.newPassword.match(pwsEx) !== null
            ? ''
            : errorsList.password.ERP101,
      confirmPassword:
        passwordObj.confirmPassword === ''
          ? errorsList.confirmPassword.ERP102
          : passwordObj.confirmPassword === passwordObj.newPassword
            ? ''
            : errorsList.confirmPassword.ERP103,
    })
    if (
      passwordObj.newPassword !== '' &&
      passwordObj.confirmPassword !== '' &&
      passwordObj.newPassword.match(pwsEx) !== null &&
      passwordObj.confirmPassword === passwordObj.newPassword
    ) {
      let changePassReq = {
        confirm_password: passwordObj.confirmPassword,
        new_password: passwordObj.newPassword,
        resettoken: router.query.resettoken,
      }
      changepassword(changePassReq).then((res) => {
        console.log('change pass response', res)
        if (res && res.status === true) {
          setPasswordUpdate(true)
          setChangePasswordAPIErr('')
        } else {
          setChangePasswordAPIErr(res.message)
        }
      })
    }
  }

  const renderlogin = () => {
    switch (type) {
      case 'Signin':
        return (
          <div className={styles.login_form_container}>
            {loginErrMsg !== '' ? <div className={styles.email_error_msg}>{loginErrMsg}</div> : ''}
            <input
              className={styles.fill_input}
              id="email"
              autoComplete="off"
              type="email"
              placeholder="EMAIL ID"
              maxLength="50"
              value={logincred.email}
              onChange={onChangeLoginEmail}
            />
            {loginErrors.email !== '' ? (
              <div className={styles.email_error_msg}>{loginErrors.email}</div>
            ) : (
              ''
            )}
            <input
              className={styles.fill_input}
              id="pass"
              type="password"
              placeholder="PASSWORD"
              autoComplete="new-password"
              maxLength="50"             
              //  readOnly={this.state.isReadOnly}
              onChange={onChangeLoginPassword}
              // onClick={() => { this.setState({ isReadOnly: false }) }} onBlur={() => { this.setState({ isReadOnly: true }) }}
            />
            {loginErrors.password !== '' ? (
              <div className={styles.email_error_msg}>{loginErrors.password}</div>
            ) : (
              ''
            )}
            <button className={styles.btn_login} type="submit" onClick={() => onClickLoginBtn()}>
              {onClickLoginLoader ? '' : 'LOGIN'}
            </button>
            {onClickLoginLoader && <div className={styles.login_loader} />}
            <div>
              <span className={styles.forgot_password} onClick={() => setType('Forgotpassword')}>
                Forgot Password
              </span>
            </div>
          </div>
        )
      case 'Signup':
        return (
          <div className={styles.login_form_container}>
            <input
              className={styles.fill_input}
              type="name"
              placeholder="First Name*"
              maxLength="50"
              value={signupForm.firstName}
              onChange={onChangeSignFName}
            />
            {signupFormErr.firstName !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.firstName}</div>
            ) : (
              ''
            )}
            <input
              className={styles.fill_input}
              type="name"
              placeholder="Last Name*"
              maxLength="50"
              value={signupForm.lastName}
              onChange={onChangeSignLName}
            />
            {signupFormErr.lastName !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.lastName}</div>
            ) : (
              ''
            )}
            <input
              className={styles.fill_input}
              type="company Name"
              placeholder="Company Name*"
              maxLength="50"
              value={signupForm.companyName}
              onChange={onChangeSignCompanyName}
            />
            {signupFormErr.companyName !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.companyName}</div>
            ) : (
              ''
            )}
            <input
              className={styles.fill_input}
              type="text"
              placeholder="Contact No*"
              maxLength="50"
              value={signupForm.mobileNo}
              onChange={onChangeSignMobile}
            />
            {signupFormErr.mobileNo !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.mobileNo}</div>
            ) : (
              ''
            )}
            <input
              className={styles.fill_input}
              type="email"
              placeholder="Email Id*"
              maxLength="50"
              value={signupForm.email}
              onChange={onChangeSignEmail}
            />
            {signupFormErr.email !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.email}</div>
            ) : (
              ''
            )}

            <textarea
              className={styles.fill_input}
              name="address"
              placeholder="Address* "
              value={signupForm.address}
              onChange={onChangeSignAddress}
              cols="20"
              rows="2"
            ></textarea>
            {signupFormErr.address !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.address}</div>
            ) : (
              ''
            )}
            <input
              className={styles.fill_input}
              type="text"
              placeholder="Enter PIN Code*"
              value={signupForm.pincode}
              onChange={onChangePinCode}
            />
            {signupFormErr.pincode !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.pincode}</div>
            ) : (
              ''
            )}

            <CountryDropdownComp
              countryFlag={originCountries}
              searchText={CountryCode}
              selectCountry={onClickNationalityHandler}
              selectedCountry={selectedNationality}
              onChangeInputCountryCode={onChangeInputNationality}
              placeholder={'Country*'}
              isSignUpNationality={true}
              {...Countrystyles}
              className={styles.countrycode_singup}
            />
            {signupFormErr.nationality !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.nationality}</div>
            ) : (
              ''
            )}

            <CountryDropdownComp
              countryFlag={cityList}
              searchText={CityCode}
              selectCountry={CityCodeClickHandler}
              selectedCountry={selectedcity}
              onChangeInputCountryCode={onChangeInputCityCode}
              placeholder={'  City*'}
              {...Countrystyles}
            />
            {signupFormErr.city !== '' ? (
              <div className={styles.email_error_msg}>{signupFormErr.city}</div>
            ) : (
              ''
            )}
            <button className={styles.btn_login} onClick={onSignUpBtn}>
              {onClickLoginLoader ? '' : 'Sign Up'}
            </button>
            {onClickLoginLoader && <div className={styles.login_loader} />}
            {isOpen && (
              <ModalPopupComponent
                modalCss={modalCss}
                onClosePopup={() => {
                  setIsOpen(false)
                  setType('Signin')
                }}
                isPopup={isOpen}
                isMobile={false}
                showCrossButton={true}
              >
                <div className={styles.modalb_wrap}>
                  <div className={styles.modb_icon}>
                    <img src="/images/email-icon.svg" alt="" />
                  </div>
                  <div className={styles.modb_title}>
                    <div> Thank you for your interest one of our account manager</div>
                    <div>will get up back to you shortly</div>
                  </div>
                </div>
              </ModalPopupComponent>
            )}
          </div>
        )
      case 'Forgotpassword':
        return (
          <>
            <div className={styles.login_form_container}>
              <div>Forgot your password?</div>
              <div>We'll send you an email with instructions</div>
              <input
                className={styles.fill_input + ' !mb-0'}
                type="email"
                placeholder="EMAIL ID"
                value={forgotPasswordEmail}
                maxLength="50"
                onChange={onChangeForgotPassEmail}
              />
              {forgotPasswordErr !== '' ? (
                <div className={styles.email_error_msg}>{forgotPasswordErr}</div>
              ) : (
                ''
              )}
              <button className={styles.btn_login} onClick={onClickForgotSubimitBtn}>
                Reset Password
              </button>
              <div className={styles.forgot_password} onClick={() => setType('Signin')}>
                {' '}
                Back to Login
              </div>
              {isOpen && (
                <ModalPopupComponent
                  modalCss={modalCss}
                  onClosePopup={() => {
                    setIsOpen(false)
                    setType('Signin')
                  }}
                  isPopup={isOpen}
                  isMobile={false}
                  showCrossButton={true}
                >
                  <div className={styles.modalb_wrap}>
                    <div className={styles.modb_icon}>
                      <img src="/images/email-icon.svg" alt="" />
                    </div>
                    <div className={styles.modb_title}>
                      <div> We just sent you an email</div>
                      <div>Please follow the instructions in the email to access your account</div>
                    </div>
                  </div>
                </ModalPopupComponent>
              )}
            </div>
          </>
        )

      default:
        return null
    }
  }

  const onClickInputCountryTraveller = () => {
    setState({
      searchCountryText: state.searchCountryText,
      demoForm: { ...state.demoForm, countryCode: '' },
      demoFormErr: { ...state.signupFormErr, countryCode: '' },
    })
  }

  const onKeyDownCountryCodeTraveller = (e) => {
    setState({
      searchCountryText: '',
      demoForm: { ...state.signupForm, countryCode: '' },
      demoFormErr: { ...state.signupFormErr, countryCode: '' },
    })
  }

  const selectCountryCodeFunctionTraveller = (ele) => {
    var numberPat = /^[0-9]*$/
    if (numberPat.test(+ele.isdCode)) {
      setState({
        searchCountryText: '+' + ele.isdCode,
        selectedCountry: ele,
        demoForm: { ...state.demoForm, countryCode: '+' + ele.isdCode },
        demoErr: { ...state.demoFormErr, countryCode: '' },
      })
    } else {
      setState({
        searchCountryText: ele.isdCode,
        selectedCountry: ele,
        demoForm: { ...state.demoForm, countryCode: ele.isdCode },
        demoFormErr: { ...state.demoFormErr, countryCode: '' },
      })
    }
  }

  const onChangeInputCountryCodeFunctionTraveller = (value) => {
    var numberPat = /^[0-9]*$/
    if (typeof value === 'number') {
      let val22 = value.toString().includes('+') ? value.toString().split('+')[1] : value //.includes('+') ? value.split('+')[1] : value;
      if (val22 !== '') {
        setState({
          searchCountryText: '+' + val22,
          demoForm: { ...state.demoForm, countryCode: '+' + val22 },
          demoFormErr: { ...state.demoFormErr, countryCode: '' },
        })
      }
    } else if (typeof value === 'string') {
      let val22 = value.includes('+') ? value.split('+')[1] : value //.includes('+') ? value.split('+')[1] : value;
      if (typeof val22 === 'number') {
        setState({
          searchCountryText: '+' + val22,
          demoForm: { ...state.demoForm, countryCode: '+' + val22 },
          demoFormErr: { ...state.demoFormErr, countryCode: '' },
        })
      } else {
        let val22 = value
        if (val22 !== '') {
          setState({
            searchCountryText: val22,
            demoForm: { ...state.demoForm, countryCode: val22 },
            demoFormErr: { ...state.demoFormErr, countryCode: '' },
          })
        }
      }
    }
  }

  const BackToLogin = () => {
    cf.redirectTo('/login')
  }
  return (
    <>
      <div className={styles.login_container}>
        {router.query &&
        router.query.resettoken &&
        router.query.resettoken !== '' &&
        router.query.resettoken !== null &&
        router.query.type.trim() === 'B2B' ? (
          <div className={styles.login_card_container}>
            <div className={styles.password_login_card}>
              <div className={styles.login_form_container}>
                {!passwordUpdate ? (
                  <>
                    {resettokenExpiry ? (
                      <InvalidPassword styles={styles} setType={setType} />
                    ) : (
                      <>
                        <div className={styles.login_by_mail_id_text}>Set a New Password</div>
                        <div className={styles.pswd_text}>
                          A strong password is at least 8 characters in length and contains letters,
                          numbers, and symbols.
                        </div>
                        {changePasswordAPIErr !== '' ? (
                          <div className={styles.email_error_msg}>{changePasswordAPIErr}</div>
                        ) : (
                          ''
                        )}
                        <input
                          className={styles.email_id}
                          id="password"
                          type="password"
                          placeholder="New Password"
                          maxLength="50"
                          onChange={onChangeNewPassword}
                        />
                        {passwordObjErr.newPassword !== '' ? (
                          <div className={styles.email_error_msg}>{passwordObjErr.newPassword}</div>
                        ) : (
                          ''
                        )}
                        <input
                          className={styles.email_id}
                          id="password"
                          type="password"
                          placeholder="Confirm New Password"
                          maxLength="50"
                          onChange={onChangeConfirmPassword}
                        />
                        {passwordObjErr.confirmPassword !== '' ? (
                          <div className={styles.email_error_msg}>
                            {passwordObjErr.confirmPassword}
                          </div>
                        ) : (
                          ''
                        )}
                        <button className={styles.btn_login} onClick={onClickConfirmbtn}>
                          Set New Password
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    <div className={styles.password_update_text}>Password Updated</div>
                    <div className={styles.password_success_text}>
                      {' '}
                      We successfully changed your password.
                    </div>
                    <div className={styles.password_success_btn}>
                      <button className={styles.btn_password} onClick={BackToLogin}>
                        Back to Login
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.button_div}>
              <button className={styles.login_btn} onClick={() => setType('Signin')}>
                Sign In
              </button>
              <button className={styles.login_btn} onClick={() => setType('Signup')}>
                Sign Up
              </button>
              {/* <button className={styles.login_btn} onClick={()=> setType("Demo")}>Request For Demo</button> */}
            </div>
            {type === 'Demo' && (
              <>
                <div className={styles.login_card_container}>
                  <div className={styles.request_info}>
                    <div className={styles.request_detail}>
                      Just fill out the form and our team will get in touch with you shortly
                    </div>
                    <div className={styles.request_title}>With Elevate Trips you can</div>
                    <ul className={styles.request_info_point}>
                      <li>Lorem ipsum is a placeholder text commonly ontent.</li>
                      <li>Lorem ipsum is a placeholder</li>
                      <li>Lorem ipsum is a placeholder text commonly ontent.</li>
                    </ul>
                    <div className={styles.request_title}>
                      Join Elevate Trips and experience Savings, Convenience, Transparency, and
                      Choice in agency travel.
                    </div>
                  </div>
                  <div className={styles.login_card}>
                    <div className={styles.login_form_container}>
                      <input
                        className={styles.fill_input}
                        id="name"
                        autoComplete="off"
                        type="name"
                        placeholder="Name*"
                        maxLength="50"
                      />
                      {/* <input className={styles.fill_input} id="Company name" autoComplete="off" type="company Name" placeholder="Company Name*" maxLength="50" /> */}
                      <div className={styles.country_drp}>
                        <div>
                          <IsdCountryDropdown
                            CountryListOptions={originCountries}
                            selectCountryCode={selectCountryCodeFunctionTraveller}
                            searchText={state.demoForm.countryCode}
                            selectedCountryInfo={state.selectedCountry}
                            onClickInputCountry={onClickInputCountryTraveller}
                            onChangeInputCountryCodeFunction={
                              onChangeInputCountryCodeFunctionTraveller
                            }
                            onKeyDownCountry={onKeyDownCountryCodeTraveller}
                            placeholder={'ISD'}
                            {...ISDstyles}
                          />
                          {/* {this.state.signupFormErr.countryCode !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.countryCode}</div> : ""} */}
                        </div>
                        <div>
                          <input
                            className={styles.fill_input}
                            type="text"
                            placeholder="Mobile No."
                            maxLength="22"
                            // value={this.state.signupForm.mobileNo} onChange={this.onChangeSignMobile}
                          />
                          {/* {this.state.signupFormErr.mobileNo !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.mobileNo}</div> : ""} */}
                        </div>
                      </div>
                      <input
                        className={styles.fill_input}
                        id="email"
                        autoComplete="off"
                        type="email"
                        placeholder="Email Id*"
                        maxLength="50"
                      />
                      <input
                        className={styles.fill_input}
                        id="city"
                        autoComplete="off"
                        type="city"
                        placeholder="City"
                        maxLength="50"
                      />
                      <input
                        className={styles.fill_input}
                        id="company"
                        autoComplete="off"
                        type="company"
                        placeholder="Company"
                        maxLength="50"
                      />
                      <button
                        className={styles.btn_login}
                        //  onClick={this.onClickLoginBtn}
                      >
                        Request a Demo
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(type === 'Signin' || type === 'Signup' || type === 'Forgotpassword') && (
              <>
                <div
                  className={
                    type === 'Signup'
                      ? `${styles.signup_card_container}`
                      : `${styles.login_card_container}`
                  }
                >
                  <div className={styles.login_card}>
                    <div className={styles.img_div}>
                      <img
                        src={`${'/shared/api/media/' + props?.home?.data?.data?.header?.logo}`}
                        alt=""
                      />
                    </div>
                    {renderlogin()}
                  </div>

                  <div class={styles.business_logo}>
                    <img
                      type="image"
                      src={`${
                        '/shared/api/media/' +
                        props?.home?.data?.data?.site_id +
                        '/' +
                        props?.home?.data?.data?.footer?.logo
                      }`}
                      alt="logo"
                    />
                  </div>
                </div>
              </>
            )}{' '}
          </>
        )}
      </div>
    </>
  )
}
