import Theme1 from './theme1'
import Theme2 from './theme2'
import Theme4 from './theme4'
import Theme5 from './theme5'

export default function Loader(props) {
  const { theme } = props
  // console.log('thme loader',props)
  switch (theme) {
    case 'theme1':
      return <Theme1 {...props} />
    case 'theme2':
      return <Theme2 {...props} />
    case 'theme4':
      return <Theme4 {...props} />
    case 'theme5':
      return <Theme5 {...props} />
    default:
      return <Theme1 {...props} />
  }
}
