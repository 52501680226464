import React, { useState, useRef, useEffect } from 'react';
import styles from "./index.module.css";
import { isdCodeRegex } from '../../../common/lib/utils/regex_cheat_sheet'

const OTPInput = ({ length, onSubmit, exp_time, onResendOtp, css, isOtpValid }) => {
  const [otp, setOTP] = useState(Array(length).fill(''));
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [disable, setDisable] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    convertTime(exp_time)
    setDisable(false)
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setMinutes(prevMinutes => prevMinutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, minutes]);

  const convertTime = (time) => {
    setMinutes(Math.floor(time / (1000 * 60)));
    setSeconds(Math.floor((time % (1000 * 60)) / 1000));
  }
  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    const nextIndex = index + 1;
    if (nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
  };
  const handlePaste = (index, e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData('text');
    const pastedDigits = clipboardData.match(/\d/g);
    if (pastedDigits && pastedDigits.length === length - index) {
      const newOTP = [...otp];
      for (let i = 0; i < pastedDigits.length; i++) {
        newOTP[index + i] = pastedDigits[i];
      }
      setOTP(newOTP);
  
      const nextIndex = index + pastedDigits.length;
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
      }
    }
  }

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      e.preventDefault();
      const newOTP = [...otp];
      newOTP[index - 1] = '';
      setOTP(newOTP);
      inputRefs.current[index - 1].focus();
    }
  };  

  const handleSubmit = () => {
    const otpValue = otp.join('');
    if(otpValue !== '' && otpValue.length == length) {
      setDisable(true)
    } else {
      setDisable(false)
    }
    onSubmit(otpValue);
  };

  const resendOtp = () => {
    convertTime(exp_time)
    setDisable(false)
    onResendOtp();
  }

  return (
    <div className={styles.mainotp}>
      <div className={styles.mainotptext}>
        <span>{`Kindly enter the ${length} digit OTP sent to your registered email id`}</span>
      </div>
      <div className={styles.otpboxesmain}>
        {otp.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value.replace(isdCodeRegex, ''))}
            ref={(ref) => (inputRefs.current[index] = ref)}
            onPaste={(e) => handlePaste(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>
      {(seconds > 0 || minutes > 0) && <button className={css.btn_login} onClick={handleSubmit} disabled={(seconds === 0 && minutes === 0) || (disable && isOtpValid) || isOtpValid}>Verify OTP</button>}
      {(seconds > 0 || minutes > 0) &&<div className={styles.timeoutotp}> {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}</div>}
      {(seconds === 0 && minutes === 0) && <button className={css.btn_login} onClick={resendOtp}>Resend OTP</button>}
      {(seconds === 0 && minutes === 0) && <div className={css.email_error_msgotp}>{'OTP has expired'}</div>}
    </div>
  );
};

export default OTPInput;
