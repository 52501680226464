import { useEffect, useState ,useRef} from 'react'
import { useRouter } from 'next/router'
import styles from './mobile.module.css'
import IsdCountryDropdown from '../../../IsdCountryDropdown/index'
import { forgotpassword } from '../../../../../common/lib/forgotpassword'
import { login, resendOtp, userLogin, userVerify } from '../../../../../common/lib/login'
import { signup, contactus } from '../../../../../common/lib/signup'
import ModalPopupComponent from '../../../../../common/components/ModalPopupComponent/ModalPopupComponent'
import modalCss from '../../../../../common/components/ModalPopupComponent/index.module.css'
// import CountryDropdownComp from '../../../../../common/components/CountryDropdown/index'
import CountryDropdownComp from '@/components/CountryDropdown/index'
import getCountriesList from '../../../../../common/lib/getCountriesList'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme4/index.module.css'
import { changepassword } from '../../../../../common/lib/changepassword'
import cf from '../../../../../common/lib/utils/common'
import InvalidPassword from './InvalidPassword'
import { tokenExpiry } from '../../../../../common/lib/tokenExpiry'
import { getCityByCountry } from '../../../../lib/myProfile'
import ISDstyles from '../../../../../common/components/IsdCountryDropdown/theme4/index.module.css'
import OTPInput from '@/components/otpInput'
import { encryptData} from "../../../../../common/lib/crypto/crypto"
import dynamic from 'next/dynamic'
const IsdCodeDropdown = dynamic(() => import('@/commonComponents/IsdCodeDropdown/index'))
import IsdCodeDropdownCss from '@/commonComponents/IsdCodeDropdown/index.module.css'
import ModalCssMobile from '@/commonComponents/ModalPopupComponent/theme5mobile/index.module.css'
import { spaceRestrict,passRestrict,isdCodeReg  } from "../../../../../common/lib/utils/regex_cheat_sheet";

const errorsList = {
  firstName: {
    ERFN101: 'Please Enter First Name',
  },
  lastName: {
    ERLN101: 'Please Enter Last Name',
  },
  companyName: {
    ERCN101: 'Please Enter Company Name',
  },
  countryCode: {
    ERCC101: 'Please Select The Country Code',
  },
  nationality: {
    ERNT101: 'Please Select Nationality',
  },
  city: {
    ERCT101: 'Please Select City',
  },
  orgName: {
    ERORG101: 'Please Select Organisation Type',
  },
  isdCode: {
    ERISD101: 'Please Enter ISD Code',
  },
  address: {
    ERAD101: 'Please Select Address',
  },
  pincode: {
    ERPN101: 'Please Select Pincode',
  },
  mobileNo: {
    ERMN101: 'Invalid Mobile Number',
    ERMN102: 'Please Enter Mobile Number',
  },
  email: {
    ERE101: 'Please Enter Email Address',
    ERE102: 'Please Enter Valid Email Address',
  },
  password: {
    ERP101:
      'Password must be between 8-13 characters in length and must comprise of at least 1 lowercase alphabet (a-z), 1 uppercase alphabet (A-Z), 1 number (0-9) and 1 special character',
    ERP104: 'Please Enter Password',
    ERP105: 'Invalid Password',
  },
  confirmPassword: {
    ERP102: 'Please Re-Enter New Password',
    ERP103: 'Passwords Do Not Match',
  },
  OTP: {
    EROTP101: 'Please Enter OTP',
    EROTP102: 'Please Enter Valid OTP',
    EROTP103: 'Invalid OTP'
  },
  isTermsChecked: {
    ERTC101: 'Please Accept The Terms & Conditions To Proceed',
  },
}

// const emailPattern = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
let pwsEx = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,13}$/)
let alphanumericAndSpecialChars = new RegExp(/^[ A-Za-z0-9_@./#&+-]*$/)
let minutes = 3;
let seconds = 0;
// var mobValidation = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export default function Theme1(props) {
  const router = useRouter()
  // console.log(router.query,"query");
  // console.log(props,"login pros page");
  const [type, setType] = useState('Signin')
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [logincred, setLogincred] = useState({ email: '', password: '' })
  const [loginErrors, setLoginErrors] = useState({ email: '', password: '', otp: '' })
  const [onClickLoginLoader, setOnClickLoginLoader] = useState(false)
  const [forgotPasswordErr, setForgotPasswordErr] = useState('')
  const [loginErrMsg, setLoginErrMsg] = useState('')
  const [signupForm, setSignupForm] = useState({
    firstName: '',
    lastName: '',
    mobileNo: '',
    companyName: '',
    nationality: '',
    email: '',
    address: '',
    pincode: '',
    city: '',
    isdCode: '',
    orgName: '',
  })
  const [signupFormErr, setSignupFormErr] = useState({
    firstName: '',
    lastName: '',
    mobileNo: '',
    companyName: '',
    nationality: '',
    email: '',
    address: '',
    pincode: '',
    city: '',
    remark: '',
    isdCode: '',
    orgName: '',
  })
  const [originCountries, setOriginCountries] = useState([])
  const [selectedNationality, setSelectedNationality] = useState('')
  const [CountryCode, setCountryCode] = useState('')
  const [passwordObj, setPasswordObj] = useState({ newPassword: '', confirmPassword: '' })
  const [passwordObjErr, setPasswordObjErr] = useState({ newPassword: '', confirmPassword: '' })
  const [passwordUpdate, setPasswordUpdate] = useState(false)
  const [changePasswordAPIErr, setChangePasswordAPIErr] = useState('')
  const [resettokenExpiry, setResetTokenExpiry] = useState(false)
  const [cityList, setCityList] = useState([])
  const [selectedcity, setselectedCity] = useState('')
  const [CityCode, setCityCode] = useState('')
  const [state, setState] = useState({
    searchCountryText: '',
    demoForm: { countryCode: '' },
    demoFormErr: { countryCode: '' },
    selectedCountry: '',
  })
  const [showPassword, setShowPassword] = useState(false);
  const [fields, setFields] = useState({emailField: false, passwordField: false, otpField: false, loginWithOtpTxt: false});
  const [showOtp, setShowOtp] = useState(false);
  const [loginWithOtp, setLoginWithOtp] = useState(true);
  const [otpLength, setOtpLength] = useState(0);
  const [exp_time, setExpTime] = useState();
  const [isOtpValid, setIsOtpValid] = useState(false);
  const imgLogo = props?.pageInfo?.data?.signin?.upload_logo
    ? '/shared/api/media/' + props?.pageInfo?.data?.signin?.upload_logo
    : 'theme4/logo-copy.png'
  const loginType = props?.pageInfo?.site?.login_type;
  const [countrydrop,showcountrydrop]=useState(false)
  const [contry_isd,setcontry_isd]=useState("")
  const [orgdrop,showorgdrop]=useState(false)
  const [organisation,setorganisation]=useState("")
  const [TempCountryListData, setTempCountryListData] = useState([])
  const [CountryISDData,setCountryISDData]=useState([])
  const OrganisationList=[
    { id: 1, label: "Construction" },
    { id: 2, label: "Defense" },
    { id: 3, label: "Educational" },
    { id: 4, label: "Engineering" },
    { id: 5, label: "Entertainment" },
    { id: 6, label: "Financial" },
    { id: 7, label: "Government" },
    { id: 8, label: "Infrastructure" },
    { id: 9, label: "Medical" },
    { id: 10, label: "Pharmaceutical" },
    { id: 11, label: "Private Sector" },
    { id: 12, label: "Public Sector" },
    { id: 13, label: "Technology" },
    { id: 14, label: "Telecom" },
    { id: 15, label: "Travel Agency" }
  ]
  const [OrganisationData,setOrganisationData]=useState([...OrganisationList])
  const [selectdropdown, setselectdropdown] = useState({
    selectedOrg:"",
    selectedCr: "",
    selectedIsd: "",
  })
  const orgFilterLayerRef = useRef(null)

  useEffect(() => {
    const cityListVar = [];
    getLoginFields(loginType);
    getCountriesList().then((resp) => {
      let countryList = []
      if (resp?.data?.length > 0) {
        resp?.data.map((item) => {
          countryList.push({
            ...item,
            value: item.contry_name,
            isdCode: item.contry_isd,
            countryCode: item.contry_code,
            flag: item.contry_flag,
            countryFlag: item.contry_flag,
            countryName: item.contry_name,
          })
        })
      }
      setOriginCountries(countryList)
    })

    // if (cityList.length === 0) {
    // let data = {
    //         "country_name": CountryCode ? CountryCode : ""

    // }
    // getCityByCountry(data).then(res => {
    //     console.log(res,"res");
    // 	if (res && res.data && res.data.length > 0) {
    // 		res.data.map((fact, index) => {
    // 			cityListVar.push({
    // 				value: fact.city_name,
    // 				countryId: index,
    // 				countryName: fact.city_name,
    // 			})
    // 		})
    // 		setCityList([...cityListVar])
    // 	}
    // })
    // }
    if (
      router.query &&
      router.query.resettoken &&
      router.query.resettoken !== '' &&
      router.query.resettoken !== null &&
      router.query.type.trim() === 'B2B'
    ) {
      let response = {
        resettoken: router?.query?.resettoken,
        site_id: props?.home?.data?.site.id,
      }
      tokenExpiry(response).then((resp) => {
        // console.log(resp);
        if (resp && resp.data.status) {
          setResetTokenExpiry(false)
        } else {
          setResetTokenExpiry(true)
        }
      })
    }
  }, [])

  function handleClickOutsideOfonStatusFilterClick(event) {
    if (orgFilterLayerRef.current && !orgFilterLayerRef.current.contains(event.target)) {
      showorgdrop(false)
      // setState({ openList: false, shouldShowFlag: true });
      document.removeEventListener('click', handleClickOutsideOfonStatusFilterClick, true)
    }
  }
  //Forgot pass
  const onChangeForgotPassEmail = (e) => {
    setForgotPasswordEmail(e.target.value)
    setForgotPasswordErr('')
  }

  const onClickForgotSubimitBtn = () => {
    setForgotPasswordErr(
      forgotPasswordEmail === ''
        ? errorsList.email.ERE101
        : forgotPasswordEmail.match(emailPattern) !== null
        ? ''
        : errorsList.email.ERE102
    )
    if (forgotPasswordEmail !== '' && forgotPasswordEmail.match(emailPattern) !== null) {
      //api call
      let forgotPassReq = {
        email: forgotPasswordEmail,
      }
      setOnClickLoginLoader(true)
      forgotpassword(forgotPassReq).then((res) => {
        // console.log('forgot pass in common', res);
        if (res && res.status === true && res.message && res.message !== '') {
          setIsOpen(true)
          setForgotPasswordErr('')
        } else {
          setIsOpen(false)
          setForgotPasswordErr(res.message)
        }
        setOnClickLoginLoader(false)
      })
    } else {
      setIsOpen(false)
    }
  }

  // Login Functinality Starts
  const onChangeLoginEmail = (e) => {
    setLogincred({ ...logincred, email: e.target.value })
    setLoginErrors({ ...loginErrors, email: '' })
  }
  const onChangeLoginPassword = (e) => {
    let spaceEX = new RegExp(/(^\S*$)/)
    let confpwsEx = new RegExp(/^(?=.{0,13}$).*/)
    if (e.target.value.match(confpwsEx) && e.target.value.match(spaceEX)) {
      setLogincred({ ...logincred, password: e.target.value })
      setLoginErrors({ ...loginErrors, password: '' })
    } else {
      setLoginErrors({ ...loginErrors, password: 'Please Enter Valid Password' })
    }
  }

  //login in button
  // const onClickLoginBtn = () => {
  //   setLoginErrors({
  //     ...loginErrors,
  //     email: logincred.email === '' ? errorsList.email.ERE101 : '',
  //     password:
  //       logincred.password === ''
  //         ? errorsList.password.ERP104
  //         : logincred.password.match(pwsEx) !== null
  //         ? ''
  //         : errorsList.password.ERP105,
  //   })
  //   setLoginErrMsg('')
  //   if (
  //     logincred.email !== '' &&
  //     logincred.password !== '' &&
  //     logincred.password.match(pwsEx) !== null
  //   ) {
  //     let loginReq = {
  //       email: logincred.email,
  //       password: logincred.password,
  //       // "type": this.state.toggleAgentLogin ? 'agent' : undefined
  //     }
  //     setOnClickLoginLoader(true)
  //     login(loginReq).then((responseJSON) => {
  //       console.log('responseJSON', responseJSON)
  //       if (
  //         responseJSON.status === true &&
  //         responseJSON.accessToken !== '' &&
  //         responseJSON.accessToken !== undefined &&
  //         responseJSON.accessToken !== null
  //       ) {
  //         // commonFunction.redirectTo('/')
  //         window.location.href = '/'
  //       } else {
  //         setLoginErrMsg(typeof responseJSON.message === 'string' ? responseJSON.message : '')
  //         setOnClickLoginLoader(false)
  //       }
  //     })
  //   }
  // }
  //Login Func Ended

  const onClickNationalityHandler = (params) => {
    setselectdropdown({ ...selectdropdown, selectedCr: params.value })
    console.log(params, 'natanality')
    setSelectedNationality(params)
    setCountryCode(params.value)
    setSignupForm({
      ...signupForm,
      nationality: params,
    })
    setSignupFormErr({ ...signupFormErr, nationality: '' })
    if (params.value) {
      const cityListVar = []
      let data = {
        country_name: params.value ? params.value : '',
      }
      getCityByCountry(data).then((res) => {
        console.log(res, 'res')
        if (res && res.data && res.data.length > 0) {
          res.data.map((fact, index) => {
            cityListVar.push({
              value: fact.city_name,
              countryId: index,
              countryName: fact.city_name,
            })
          })
          setCityList([...cityListVar])
          setCityCode('')
          setselectedCity({
            countryId: 0,
            countryName: '',
            value: '',
          })
        }
      })
    }
  }

  const onChangeInputNationality = (params) => {
    setselectdropdown({ ...selectdropdown, selectedCr: "" })
    setSelectedNationality({ value: params.target.value })
    setCountryCode(params.target.value)
    setSignupForm({ ...signupForm, nationality: params.target.value })
  }

  function CityCodeClickHandler(params) {
    console.log('CityCodeClickHandler', params)
    setselectedCity(params)
    setCityCode(params.value)
    setSignupForm({
      ...signupForm,
      city: { value: params.value, countryId: params.countryId, countryName: params.countryName },
    })
    setSignupFormErr({ ...signupFormErr, city: '' })
  }

  function onChangeInputCityCode(params) {
    setCityCode(params.target.value)
    setselectedCity({})
    setSignupForm({ ...signupForm, city: params.target.value })
  }

  // signup

  const onChangeSignFName = (e) => {
    let uppercase = e.target.value.replace(/[^A-Za-z ]+/g, '').toUpperCase() 
    if (e.target.value.charCodeAt(0) !== 32 && e.target.value.match(alphanumericAndSpecialChars)) {
      setSignupForm({ ...signupForm, firstName: uppercase })
      setSignupFormErr({ ...signupFormErr, firstName: '' })
    }
  }

  const onChangeSignLName = (e) => {
    let uppercase = e.target.value.replace(/[^A-Za-z ]+/g, '').toUpperCase() 
    if (e.target.value.charCodeAt(0) !== 32 && e.target.value.match(alphanumericAndSpecialChars)) {
      setSignupForm({ ...signupForm, lastName: uppercase })
      setSignupFormErr({ ...signupFormErr, lastName: '' })
    }
  }

  const onChangeSignMobile = (e) => {
    setSignupForm({ ...signupForm, mobileNo: e.target.value.replace(/[^0-9]+/g, '') })
    setSignupFormErr({ ...signupFormErr, mobileNo: '' })
  }

  const onChangeSignCompanyName = (e) => {
    setSignupForm({ ...signupForm, companyName: e.target.value.replace(/[^a-zA-Z0-9 ]/gi, '') })
    setSignupFormErr({ ...signupFormErr, companyName: '' })
  }
  const onChangeRemarks = (e) => {
    setSignupForm({ ...signupForm, remark: e.target.value.replace(/[^a-zA-Z0-9 ]/gi, '') })
  }

  const onChangeSignEmail = (e) => {
    setSignupForm({ ...signupForm, email: e.target.value.replace(/\s/g, '') })
    setSignupFormErr({ ...signupFormErr, email: '' })
  }

  const onChangeSignAddress = (e) => {
    setSignupForm({ ...signupForm, address: e.target.value })
    setSignupFormErr({ ...signupFormErr, address: '' })
  }
  const onChangePinCode = (e) => {
    setSignupForm({ ...signupForm, pincode: e.target.value.replace(/[^0-9]+/g, '') })
    setSignupFormErr({ ...signupFormErr, pincode: '' })
  }

  const onSignUpBtn = () => {
    setSignupFormErr({
      ...signupFormErr,
      firstName:
        signupForm.firstName === '' || signupForm.firstName === undefined
          ? errorsList.firstName.ERFN101
          : '',
      lastName:
        signupForm.lastName === '' || signupForm.lastName === undefined
          ? errorsList.lastName.ERLN101
          : '',
      mobileNo:
        signupForm.mobileNo === '' || signupForm.mobileNo === undefined
          ? errorsList.mobileNo.ERMN102
          : signupForm.mobileNo.length > 3
          ? ''
          : errorsList.mobileNo.ERMN101,
      companyName:
        signupForm.companyName === '' || signupForm.companyName === undefined
          ? errorsList.companyName.ERCN101
          : '',
      email:
        signupForm.email === '' || signupForm.email === undefined
          ? errorsList.email.ERE101
          : signupForm.email.match(emailPattern) !== null
          ? ''
          : errorsList.email.ERE102,
          nationality:selectdropdown?.selectedCr==""? errorsList.nationality.ERNT101: '',
          orgName: selectdropdown?.selectedOrg =="" ? errorsList.orgName.ERORG101 : '',
          isdCode: selectdropdown?.selectedIsd =="" ? errorsList.isdCode.ERISD101 : '',
      // pincode: (signupForm.pincode === '' || signupForm.pincode === undefined) ? errorsList.pincode.ERPN101 : '',
    })
    if (
      signupForm.email !== '' &&
      signupForm.firstName !== '' &&
      signupForm.mobileNo !== '' &&
      signupForm.mobileNo.length > 3 &&
      signupForm.lastName !== '' &&
      signupForm.companyName !== '' &&
      selectdropdown?.selectedCr!=""&&
      selectdropdown?.selectedOrg!=""&&
      selectdropdown?.selectedIsd!=""
    ) {
      let isd_code = state.demoForm.countryCode.replace("+", "")
      let signupReq = {
        site_id: props?.home?.data?.site.id || '',
        first_name: signupForm.firstName,
        last_name: signupForm.lastName,
        company_name: signupForm.companyName,
        mobile_number: signupForm.mobileNo,
        email: signupForm.email,
        country: {
          name :selectedNationality.countryName,
        code:selectedNationality.contry_code},
                            // changed the key nationality ->country
        city: {
          name: "",
          code: '',
        },
        address: signupForm.address,
        pincode: signupForm.pincode,
        remark: signupForm.remark,
        company_type: signupForm.orgName,
        isd_code:isd_code,
        // type: 'B2B',
      }
      // console.log(signupReq,"signupReq");
      setOnClickLoginLoader(true)
      contactus(signupReq).then((res) => {
        //  console.log(res,"singup res");
        if (res && res.status && res.message !== '') {
          setSignupFormErr({ email: '' })
          setIsOpen(true)
          setOnClickLoginLoader(false)
        } else {
          setSignupFormErr({
            email:
              typeof res.message === 'string'
                ? res.message
                : res.message && res.message.length > 0
                ? res.message[0].msg
                : 'some thing went wrong',
          })
          setOnClickLoginLoader(false)
        }
      })
    }
  }

  const onChangeNewPassword = (e) => {
    
    if (e.target.value.match(spaceRestrict) && e.target.value.match(passRestrict)) {
      setPasswordObj({ ...passwordObj, newPassword: e.target.value })
      setPasswordObjErr({ ...passwordObjErr, newPassword: '' })
    } 
  } 

  const onChangeConfirmPassword = (e) => {
 
    if (e.target.value.match(spaceRestrict) && e.target.value.match(passRestrict)) {
      setPasswordObj({ ...passwordObj, confirmPassword: e.target.value })
      setPasswordObjErr({ ...passwordObjErr, confirmPassword: '' })
    } 
  }
  const onClickConfirmbtn = () => {
    setPasswordObjErr({
      ...passwordObjErr,
      newPassword:
        passwordObj.newPassword === ''
          ? errorsList.password.ERP104
          : passwordObj.newPassword.match(pwsEx) !== null
          ? ''
          : errorsList.password.ERP101,
      confirmPassword:
        passwordObj.confirmPassword === ''
          ? errorsList.confirmPassword.ERP102
          : passwordObj.confirmPassword === passwordObj.newPassword
          ? ''
          : errorsList.confirmPassword.ERP103,
    })
    if (
      passwordObj.newPassword !== '' &&
      passwordObj.confirmPassword !== '' &&
      passwordObj.newPassword.match(pwsEx) !== null &&
      passwordObj.confirmPassword === passwordObj.newPassword
    ) {
      let changePassReq = {
        confirm_password: passwordObj.confirmPassword,
        new_password: passwordObj.newPassword,
        resettoken: router.query.resettoken,
      }
      changepassword(changePassReq).then((res) => {
        console.log('change pass response', res)
        if (res && res.status === true) {
          setPasswordUpdate(true)
          setChangePasswordAPIErr('')
        } else {
          setChangePasswordAPIErr(res.message)
        }
      })
    }
  }

  const onChangeOrg=(value,type)=>{
    // value = values.replace(/[^0-9]/g, '')
    let values = value.replace(/[^a-zA-Z]/gi, '').trim()
    console.log('value===========*******', value)
    showorgdrop(true)
    setSignupForm({ ...signupForm, orgName: value })
    setSignupFormErr({ ...signupFormErr, orgName: '' })
    type=="change"?setselectdropdown({ ...selectdropdown, selectedOrg: "" }):setselectdropdown({ ...selectdropdown, selectedOrg: values })

    let selectedOrganization = []
    if (value !== '') {
      selectedOrganization = OrganisationList.filter((item) =>item.label.toLowerCase().includes(value.toLowerCase()))
    } else {
      selectedOrganization = OrganisationList
    }
    setOrganisationData([...selectedOrganization])
}

  const renderlogin = () => {
    switch (type) {
      case 'Signin':
        return (
          <div className={styles.login_form_container}>
            {fields.emailField &&
              <>
                <label className={styles.label_common}>Email Address</label>
              <div className={styles.emailtop}>
                <span>
                  <img src="theme4/email.svg" alt="email" />
                </span>
                <input
                  className={styles.fill_input}
                  id="email"
                  autoComplete="off"
                  type="email"
                  placeholder="Enter email address"
                  maxLength="60"
                  value={logincred.email}
                  onChange={onChangeLoginEmail}
                />
                {loginErrMsg !== '' && loginErrMsg == "User Does not Exists" ? <div className={styles.email_error_msg}>{loginErrMsg}</div> : ''}
                  {loginErrMsg !== '' && loginErrMsg == 'Please enter a valid email address.' ? (
                    <div className={styles.email_error_msg}>{loginErrMsg}</div>
                  ) : (
                    ''
                  )}
              </div>
              {loginErrors.email !== '' ? (
                <div className={styles.email_error_msg}>{loginErrors.email}</div>
              ) : (
                ''
              )}
              </>
            }
            {fields.passwordField && !showOtp &&
              <>
              <label className={styles.label2_common}>Password</label>
              <div className={styles.passtop}>
                <span>
                  <img src="theme4/pass.svg" alt="pass" />
                </span>
                <input
                  className={styles.fill_input}
                  id="pass"
                  type="password"
                  placeholder="Enter password"
                  autoComplete="new-password"
                  maxLength="50"                  
                  //  readOnly={this.state.isReadOnly}
                  onChange={onChangeLoginPassword}
                  // onClick={() => { this.setState({ isReadOnly: false }) }} onBlur={() => { this.setState({ isReadOnly: true }) }}
                />
                 {loginErrMsg !== '' && loginErrMsg == 'Invalid Password' ? (
                    <div className={styles.email_error_msg}>{loginErrMsg}</div>
                  ) : (
                    ''
                  )}
                  
                  {loginErrMsg !== '' && loginErrMsg != 'Invalid Password' && loginErrMsg != 'Please enter a valid email address.' && 
                   loginErrMsg != "User Does not Exists" ? (
                    <div className={styles.email_error_msg}>{loginErrMsg}</div>) : (''
                    )}
              </div>
              {loginErrors.password !== '' ? (
                <div className={styles.email_error_msg}>{loginErrors.password}</div>
              ) : (
                ''
              )}
              </>
            }
            {showOtp && 
              <>
                <OTPInput length={otpLength} onSubmit={handleSubmitOTP} exp_time={exp_time} onResendOtp={onResendOtp} css={styles} isOtpValid={isOtpValid} />
                {loginErrors.otp !== '' ? (
                  <div className={styles.email_error_msgotp}>{loginErrors.otp}</div>
                ) : (
                    ''
                )}
              </>
            }
            {!showOtp &&
              <>
              <button className={`${onClickLoginLoader ? styles.btn_login + ' ' + 'loading' : styles.btn_login}`} type="submit" onClick={() => onClickLoginBtn()}>
                {/* {onClickLoginLoader ? '' : 'Sign In'} */}
                {'Sign In'}
              </button>
              {/* {onClickLoginLoader && <div className={styles.login_loader_up} />} */}
              <div className={styles.fget_pass}>
                <span className={styles.forgot_password} onClick={() => setType('Forgotpassword')}>
                  Forgot Password?
                </span>
              </div>
              <div className={styles.cget_us}>
                <span onClick={() => setType('Signup')} className={styles.contact_us}>
                  Contact Us
                </span>
              </div>
              </>
            }
            {fields.loginWithOtpTxt && !showOtp &&
              <div className={styles.fget_pass} onClick={() => onClickLoginWithOtp()}>
                  <span className={styles.forgot_password}>
                      Login with {loginWithOtp ? 'OTP' : 'Password'}
                  </span>
              </div>
            }
            <span className={styles.sec_msg}>
              Entry into this system is private, and unauthorized access is strictly prohibited.
            </span>
          </div>
        )
      case 'Signup':
        return (
          <div className={styles.login_form_container}>
            <div className={styles.topdiv}>
              <label className={styles.firstblock}>First Name</label>
              <div className={styles.fname_image}>
                <span>
                  <img src="theme4/user.svg" alt="user" />
                </span>
                <input
                  className={styles.fill_input}
                  type="name"
                  placeholder="First Name*"
                  maxLength="25"
                  value={signupForm.firstName}
                  onChange={onChangeSignFName}
                />
                {signupFormErr.firstName !== '' ? (
                  <div className={styles.email_error_msg}>{signupFormErr.firstName}</div>
                ) : (
                  ''
                )}
              </div>
              <label className={styles.secondblock}>Last Name</label>
              <div className={styles.lname_image}>
                <span>
                  <img src="theme4/user.svg" alt="user" />
                </span>
                <input
                  className={styles.fill_input}
                  type="name"
                  placeholder="Last Name*"
                  maxLength="25"
                  value={signupForm.lastName}
                  onChange={onChangeSignLName}
                />
                {signupFormErr.lastName !== '' ? (
                  <div className={styles.email_error_msg}>{signupFormErr.lastName}</div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <label className={styles.secondblock}>Email Address</label>
            <div className={styles.emailt4}>
              <span>
                <img src="theme4/email.svg" alt="email" />
              </span>
              <input
                className={styles.fill_input}
                type="email"
                placeholder="Email Id*"
                maxLength="60"
                value={signupForm.email}
                onChange={onChangeSignEmail}
              />
              {signupFormErr.email !== '' ? (
                <div className={styles.email_error_msg}>{signupFormErr.email}</div>
              ) : (
                ''
              )}
            </div>
            <label className={styles.secondblock}>Country</label>
            <div className={styles.country_div}>
              <div className={styles.countrynames}>
                <span className={styles.countryimg}>
                  <img src="theme4/flag.png" alt="countrycode" />
                </span>
                <CountryDropdownComp
                  theme="theme4"
                  countryFlag={originCountries}
                  searchText={CountryCode}
                  selectCountry={onClickNationalityHandler}
                  selectedCountry={selectedNationality}
                  onChangeInputCountryCode={onChangeInputNationality}
                  placeholder={'Country*'}
                  isSignUpNationality={true}
                  {...Countrystyles}
                  className={styles.countrycode_singup}
                  isSignup={true}
                />
                {signupFormErr.nationality !== '' ? (
                  <div className={styles.email_error_msg}>{signupFormErr.nationality}</div>
                ) : (
                  ''
                )}
              </div>
              <div className={styles.contact_container}>
              <label className={styles.secondblock}>Contact No.</label>
              <div className={styles.contact_main_flex}>
                <div className={styles.isd_main}>
                {/* <IsdCountryDropdown
                            CountryListOptions={originCountries}
                            selectCountryCode={selectCountryCodeFunctionTraveller}
                            searchText={state?.demoForm?.countryCode||""}
                            selectedCountryInfo={state.selectedCountry}
                            onClickInputCountry={onClickInputCountryTraveller}
                            onChangeInputCountryCodeFunction={
                              onChangeInputCountryCodeFunctionTraveller
                            }
                            onKeyDownCountry={onKeyDownCountryCodeTraveller}
                            placeholder={'ISD*'}
                            {...ISDstyles}
                            showimg={false}
                            showcountryname={false}
                          /> */}
                          <IsdCodeDropdown
                            label="ISD Code"
                            placeholder="ISD Code"
                            showFlag={true}
                            showCountryCode={true}
                            showCountryName={true}
                            showISDCode={true}
                            onSelect={(country) => { handleIsdSelect(country) }}
                            styles={IsdCodeDropdownCss}
                            value={state?.demoForm?.countryCode}
                            onClick={onClickIsd}
                            isMobile={props.isMobile}
                            showLabel={props.isMobile ? true : false}
                            ModalCssMobile={ModalCssMobile}
                            autoPopulateOnOutsideClick={false}
                          />
                          {
                  <div className={styles.email_error_msg}>{signupFormErr.isdCode}</div>
                }
                  </div>
              <div className={styles.contactnumber}>
                <img src="theme4/contactno.svg" alt="contactno" />
                <input
                  className={styles.fill_input}
                  type="text"
                  placeholder="Enter mobile no."
                  maxLength="15"
                  value={signupForm.mobileNo}
                  onChange={onChangeSignMobile}
                />
                {signupFormErr.mobileNo !== '' ? (
                  <div className={styles.email_error_msg}>{signupFormErr.mobileNo}</div>
                ) : (
                  ''
                )}
              </div>
              </div>
              </div>
            </div>
            <div className={styles.organisation_select}>
              <label className={styles.secondblock}>Organization Type</label>
                <div className={styles.organisation_inputCont}>
              <input class='abx' ref={orgFilterLayerRef} id={`organization_list`} type="text" name="" onClick={() => { 
                                                    showorgdrop(true)
                                                    onClickOrgName()
                                                }} 
                                                    value={signupForm?.orgName}
                                                    className={`${styles.login_traveller_input} ${styles.ax_border}`} autocomplete="off" 
                                                    onChange={(e) => {  onChangeOrg(e.target.value,'change')}} 
                                                     placeholder="Organization Type*" 
                                                     onClick={(e) => {  onChangeOrg('','change')}} 
                                                     />
                                                     <span className={styles.drop_iconnw}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8"><path fill-rule="evenodd" fill="#01445F" d="M11.606.458c.704-.008 1.103.082 1.515.021a1.073 1.073 0 0 1-.021 1.517L7.746 7.198l-.747-.769.748.772a1.072 1.072 0 0 1-1.554-.061L.942 1.996A1.074 1.074 0 0 1 .921.479C1.334.54 2.12.45 2.437.458L7.2 4.931 11.606.458z"></path></svg>
                                                     </span>
                                                      {/* <img className={styles.dropimg_traveller} src="theme4/drop-green-arrow.svg" alt="arrow" width="12" height="8" /> */}
                                                </div>
                                                      {orgdrop === true ? (
                                                <div className={styles.organisation_drpdwn} 
                                                onClick={() => { }} 
                                                id="organization_list">
                                                    {OrganisationData?.map((ele) => {
                                                        return <div className={styles.orgtitle_element} 
                                                        onClick={(e) => { 
                                                          onChangeOrg(ele?.label,'auto')
                                                          showorgdrop(false)
                                                          }}>
                                                            {/* <img src={ele.contry_flag} alt={ele.contry_name + ' country'}/>  */}
                                                            <span>{ele.label}</span> 
                
                                                        </div>
                                                    })}
                                                </div>
                                            ) : null}
                                            {/* <img className={styles.dropimg_traveller} src="theme4/drop-green-arrow.svg" alt="" width="12" height="8" /> */}
                                            {signupFormErr?.orgName&& <span id={`organization_list`} className={styles.email_error_msg}>{signupFormErr?.orgName}</span>}
                  </div>
            <label className={styles.secondblock}>Company Name</label>
            <div className={styles.t_company_name}>
              <img src="theme4/companyname.svg" alt="companyname" />
              <input
                className={styles.fill_input}
                type="company Name"
                placeholder="Company Name*"
                maxLength="100"
                value={signupForm.companyName}
                onChange={onChangeSignCompanyName}
              />
              {signupFormErr.companyName !== '' ? (
                <div className={styles.email_error_msg}>{signupFormErr.companyName}</div>
              ) : (
                ''
              )}
            </div>
            <label className={styles.secondblock}>Remark</label>
            <div className={styles.t_company_name}>
              <img src="theme4/report.png" alt="companyname" />
              <input
                className={styles.fill_input}
                type="remark"
                placeholder="Remark"
                maxLength="200"
                value={signupForm.remark}
                onChange={onChangeRemarks}
              />
            </div>
            {/* <div className={styles.t_address4}>
                    <img src="theme4/address.svg"/>
                <textarea className={styles.fill_input} name="address" placeholder='Address* ' value={signupForm.address} onChange={onChangeSignAddress}  cols="20" rows="2" ></textarea>
                 {signupFormErr.address !== "" ? <div className={styles.email_error_msg}>{signupFormErr.address}</div> : ""}</div> 
                <div className={styles.t_pincode}>
                    <img src="theme4/zipcode.svg"/>
                <input className={styles.fill_input} type='text' placeholder='Enter PIN Code*'  value={signupForm.pincode} onChange={onChangePinCode}  />
                 {signupFormErr.pincode !== "" ? <div className={styles.email_error_msg}>{signupFormErr.pincode}</div> : ""}</div> */}

            {/* <div className={styles.t_cdropdown}>
                    <img src="theme4/city.svg"/>
                   <CountryDropdownComp
                    countryFlag={cityList}
                    searchText={CityCode}
                    selectCountry={CityCodeClickHandler}
                    selectedCountry={selectedcity}
                    onChangeInputCountryCode={onChangeInputCityCode}
                    placeholder={'  City*'}
                    {...Countrystyles}
                        />
              {signupFormErr.city !== "" ? <div className={styles.email_error_msg}>{signupFormErr.city}</div> : ""}</div> */}
            <button className={`${onClickLoginLoader ? styles.btn_login + ' ' + 'loading' : styles.btn_login}`} onClick={onSignUpBtn}>
              {/* {onClickLoginLoader ? '' : 'Submit'} */}
              {'Submit'}
            </button>
            <div className={styles.forgot_password} onClick={() => {
                      setType('Signin')
                      setSignupForm({...signupForm,firstName:'',lastName:'',companyName:'',mobileNo:'',email:'',orgName:"",isdCode:'',remark:""})
                      setselectdropdown({ ...selectdropdown, selectedCr: "",selectedOrg:"",selectedIsd:"" })
                      setCountryCode("")
                      setState({});
                      setSelectedNationality("")
                    }}>
              {' '}
              Back to Login
            </div>
            {/* {onClickLoginLoader && <div className={styles.login_loader} />} */}
            {isOpen && (
              <ModalPopupComponent
                modalCss={modalCss}
                onClosePopup={() => {
                  setIsOpen(false)
                  setType('Signin')
                }}
                isPopup={isOpen}
                isMobile={false}
                showCrossButton={true}
              >
                <div className={styles.modalb_wrap}>
                  <div className={styles.modb_icon}>
                    <img src="/images/email-icon.svg" alt="email" />
                  </div>
                  <div className={`${styles.modb_title} `}>
                    <div>Thanks for your interest in collaborating with us.</div>
                    <div>
                      Our Head of Partnership, is excited to see what we can do together! We’ll be
                      in touch soon to schedule a time to hear your great ideas.
                    </div>
                  </div>
                  <button
                    className={`${styles.btn_login} ${styles.mobokbtn}`}
                    onClick={() => {
                      setIsOpen(false)
                      setType('Signin')
                      setSignupForm({...signupForm,firstName:'',lastName:'',companyName:'',mobileNo:'',email:'',orgName:"",isdCode:'',remark:""})
                      setselectdropdown({ ...selectdropdown, selectedCr: "",selectedOrg:"",selectedIsd:"" })
                      setCountryCode("")
                      setState({})
                      setSelectedNationality("")
                    }}
                  >
                    OK{' '}
                  </button>
                </div>
              </ModalPopupComponent>
            )}
          </div>
        )
      case 'Forgotpassword':
        return (
          <>
            <div className={styles.login_form_container}>
              <div className={styles.forgot_pass}>Forgot your password?</div>
              <div className={styles.inst_forgot}>We'll send you an email with instructions</div>
              <label className={styles.label2_common}>Email Address</label>
              <div className={styles.forgot_email}>
                <span>
                  <img src="theme4/email.svg" alt="email" />
                </span>
                <input
                  className={styles.fill_input + ' !mb-0'}
                  type="email"
                  placeholder="Enter email address"
                  value={forgotPasswordEmail}
                  maxLength="50"
                  onChange={onChangeForgotPassEmail}
                />
              </div>
              {forgotPasswordErr !== '' ? (
                <div className={styles.email_error_msg}>{forgotPasswordErr}</div>
              ) : (
                ''
              )}
              <div className={styles.btn_wrap}>
                <button className={`${onClickLoginLoader ? styles.btn_login + ' ' + 'loading' : styles.btn_login}`} onClick={onClickForgotSubimitBtn}>
                  {/* {onClickLoginLoader ? '' : 'Submit'} */}
                  {'Submit'}
                </button>
                {/* {
                  onClickLoginLoader && (
                    <div className={styles.login_loader_up}></div>
                  )
                } */}
              </div>
              <div className={styles.forgot_password} onClick={() => setType('Signin')}>
                {' '}
                Back to Sign In
              </div>
              {isOpen && (
                <ModalPopupComponent
                  modalCss={modalCss}
                  onClosePopup={() => {
                    setIsOpen(false)
                    setType('Signin')
                  }}
                  isPopup={isOpen}
                  isMobile={false}
                  showCrossButton={true}
                >
                  <div className={styles.modalb_wrap}>
                    <div className={styles.modb_icon}>
                      <img src="/images/email-icon.svg" alt="email" />
                    </div>
                    <div className={styles.modb_title}>
                      <div> We just sent you an email</div>
                      <div>Please follow the instructions in the email to access your account</div>
                    </div>
                  </div>
                </ModalPopupComponent>
              )}
            </div>
          </>
        )

      default:
        return null
    }
  }

  const onClickInputCountryTraveller = () => {
    setState({
      searchCountryText: state.searchCountryText,
      demoForm: { ...state.demoForm, countryCode: '' },
      demoFormErr: { ...state.signupFormErr, countryCode: '' },
    })
    signupFormErr.isdCode = ''
  }

  const onKeyDownCountryCodeTraveller = (e) => {
    setState({
      searchCountryText: '',
      demoForm: { ...state.signupForm, countryCode: '' },
      demoFormErr: { ...state.signupFormErr, countryCode: '' },
    })
    signupFormErr.isdCode = ''
  }

  const selectCountryCodeFunctionTraveller = (ele) => {
    setselectdropdown({ ...selectdropdown, selectedIsd: ele })
    var numberPat = /^[0-9]*$/
    if (numberPat.test(+ele.isdCode)) {
      setState({
        searchCountryText: '+' + ele.isdCode,
        selectedCountry: ele,
        demoForm: { ...state.demoForm, countryCode: '+' + ele.isdCode },
        demoErr: { ...state.demoFormErr, countryCode: '' },
      })
    } else {
      setState({
        searchCountryText: ele.isdCode,
        selectedCountry: ele,
        demoForm: { ...state.demoForm, countryCode: ele.isdCode },
        demoFormErr: { ...state.demoFormErr, countryCode: '' },
      })
    }
    signupFormErr.isdCode = ''
  }

  const handleIsdSelect = (ele) => {
    setselectdropdown({ ...selectdropdown, selectedIsd: ele ? ele : '' })
    if(ele) {
      var numberPat = isdCodeReg;
      if (numberPat.test(+ele.contry_isd)) {
        setState({
          searchCountryText: '+' + ele.contry_isd,
          selectedCountry: ele,
          demoForm: { ...state.demoForm, countryCode: '+' + ele.contry_isd },
          demoErr: { ...state.demoFormErr, countryCode: '' },
        })
      } else {
        setState({
          searchCountryText: ele.contry_isd,
          selectedCountry: ele,
          demoForm: { ...state.demoForm, countryCode: ele.contry_isd },
          demoFormErr: { ...state.demoFormErr, countryCode: '' },
        })
      }
      signupFormErr.isdCode = ''
    }
  }

  const onClickIsd = () => {
    setState({
      searchCountryText: '',
      selectedCountry: '',
      demoForm: { ...state.demoForm, countryCode: '' },
      demoErr: { ...state.demoFormErr, countryCode: '' },
      demoFormErr: { ...state.demoFormErr, countryCode: '' }
    })
    setselectdropdown({ ...selectdropdown, selectedIsd: '' })
  }

  const onChangeInputCountryCodeFunctionTraveller = (value) => {
    var numberPat = isdCodeReg;
    if (typeof value === 'number') {
      let val22 = value.toString().includes('+') ? value.toString().split('+')[1] : value //.includes('+') ? value.split('+')[1] : value;
      if (val22 !== '') {
        setState({
          searchCountryText: '+' + val22,
          demoForm: { ...state.demoForm, countryCode: '+' + val22 },
          demoFormErr: { ...state.demoFormErr, countryCode: '' },
        })
      }
    } else if (typeof value === 'string') {
      let val22 = value.includes('+') ? value.split('+')[1] : value //.includes('+') ? value.split('+')[1] : value;
      if (typeof val22 === 'number') {
        setState({
          searchCountryText: '+' + val22,
          demoForm: { ...state.demoForm, countryCode: '+' + val22 },
          demoFormErr: { ...state.demoFormErr, countryCode: '' },
        })
      } else {
        let val22 = value
        if (val22 !== '') {
          setState({
            searchCountryText: val22,
            demoForm: { ...state.demoForm, countryCode: val22 },
            demoFormErr: { ...state.demoFormErr, countryCode: '' },
          })
        }
      }
    }
    signupFormErr.isdCode = ''
  }

  const BackToLogin = () => {
    cf.redirectTo('/login')
  }

  const onClickLoginWithOtp = () => {
    setFields({...fields, passwordField: loginWithOtp ? false : true});
    setLoginErrors({ ...loginErrors, email: '', password: '' })
    setLoginWithOtp(!loginWithOtp)
}

  const getLoginFields = (type) => {
    switch(type) {
      case 'only_password':
        setFields(prevState => ({...prevState, emailField: true, passwordField: true, otpField: false, loginWithOtpTxt: false}));
        break;
      case 'only_otp':
        setFields(prevState => ({...prevState, emailField: true, passwordField: false, otpField: true, loginWithOtpTxt: false}));
        break;
      case 'password_or_otp':
        setFields(prevState => ({...prevState, emailField: true, passwordField: true, otpField: true, loginWithOtpTxt: true}));
        break;
      case 'two_step_auth':
        setFields(prevState => ({...prevState, emailField: true, passwordField: true, otpField: true, loginWithOtpTxt: false}));
        break;
      default:
        setFields(prevState => ({...prevState, emailField: true, passwordField: true, otpField: false, loginWithOtpTxt: false}));
        break;
    }
  }

  //login in button
  const onClickLoginBtn = () => {
    if(loginType == 'only_otp' || (loginType == 'password_or_otp' && !loginWithOtp)) {
      setLoginErrors({
        ...loginErrors,
        email: logincred.email === '' ? errorsList.email.ERE101 : '',
        password: ''
      })
    } else {
      setLoginErrors({
        ...loginErrors,
        email: logincred.email === '' ? errorsList.email.ERE101 : '',
        password:
          logincred.password === ''
            ? errorsList.password.ERP104
            : logincred.password.match(pwsEx) !== null
            ? ''
            : errorsList.password.ERP105,
      })
    }
    setLoginErrMsg('')
    if((loginType == 'only_password') || (loginType == 'password_or_otp' && loginWithOtp) || (loginType == 'two_step_auth')) {
      onLoginWithPass();
    } else if(loginType == 'only_otp' || (loginType == 'password_or_otp' && !loginWithOtp)) {
      if(logincred.email) onLoginWithOtp();
    } else {
      onLoginWithPass();
    }
  }
  //Login Func Ended

  const onLoginWithPass = () => {
    if (
      logincred.email !== '' &&
      logincred.password !== '' &&
      logincred.password.match(pwsEx) !== null
    ) {
      let encryptedPassword = encryptData(logincred.password);  
      let loginReq = {
        email: logincred.email,
        password: encryptedPassword,
        "crypto" :true ? true : false
        // "type": this.state.toggleAgentLogin ? 'agent' : undefined       
      }
    setOnClickLoginLoader(true);
    login(loginReq).then((responseJSON) => {
      if(loginType != 'two_step_auth') {
        if (
          responseJSON.status === true &&
          responseJSON.accessToken !== '' &&
          responseJSON.accessToken !== undefined &&
          responseJSON.accessToken !== null
          ) {
            // commonFunction.redirectTo('/')
            window.location.href = '/'
          } else {
            setLoginErrMsg(typeof responseJSON.message === 'string' ? responseJSON.message : '')
            setOnClickLoginLoader(false)
          }
        } else {
          if(responseJSON.status === true) {
            if(responseJSON.login_type == 'only_password') {
              window.location.href = '/';
            } else {
              if(responseJSON?.otp?.send_mail) {
                setExpTime(responseJSON?.otp?.otp_expiry_time || 180000);
                setShowOtp(true);
                setOtpLength(responseJSON?.otp?.otp_length)
                setFields({...fields, emailField: false, passwordField: false});
              }
            }
          } else {
            setLoginErrMsg(typeof responseJSON.message === 'string' ? responseJSON.message : '')
            setOnClickLoginLoader(false);
          }
        }
      })
    }
  }

  const onLoginWithOtp = () => {
    let loginReq = {
      email: logincred.email     
    }
    userLogin(loginReq).then((responseJSON) => {
      if (responseJSON.status === true) {
        if(responseJSON?.send_mail) {
          setExpTime(responseJSON?.otp_expiry_time || 180000);
          setOtpLength(responseJSON?.otp_length);
          setShowOtp(true); 
          setFields({...fields, emailField: false, otpField: true})
        }
      } else {
        setShowOtp(false);
        setLoginErrMsg(typeof responseJSON.message === 'string' ? responseJSON.message : '')
        setOnClickLoginLoader(false)
      }
    })
  }

  const handleSubmitOTP = (otp) => {
    setLoginErrors({ ...loginErrors, otp: !otp ? errorsList.OTP.EROTP101 : otp.length != otpLength ? errorsList.OTP.EROTP102 : '' })

    if(otp != '' && otp.length == otpLength) {
      setIsOtpValid(true)
      let loginReq = {
        "email": logincred.email,
        "otp": otp,
        "password": ""
      }
      userVerify(loginReq).then((responseJSON) => {
        if (responseJSON.status === true) {
          setIsOtpValid(true)
          window.location.href = '/';
          setLoginErrors({ ...loginErrors, otp: '' })
        } else {
          setIsOtpValid(false)
          setLoginErrors({ ...loginErrors, otp: responseJSON?.message ? responseJSON?.message : errorsList.OTP.EROTP103 })
        }
      })
    }
  }

  const onResendOtp = () => {
    let loginReq = {
      email: logincred.email     
    }
    resendOtp(loginReq).then((responseJSON) => {
      console.log('responseJSON', responseJSON)
      if (responseJSON.status === true) {
        console.log("");
      } else {
        console.log("");
      }
    })
  }
  return (
    <>
      <div className={`${styles.login_container} ${router.query.resettoken ? styles.reset_mainCard_container : ''}`}>
        {router.query &&
        router.query.resettoken &&
        router.query.resettoken !== '' &&
        router.query.resettoken !== null &&
        (router.query.type.trim() === 'B2B' || router.query.type.trim() === 'B2E') ? (
          <div className={`${styles.login_card_container} ${router.query.resettoken ? styles.reset_card_container : ''}`}>
            <div className={styles.password_login_card}>
              <div className={styles.login_form_container}>
                {!passwordUpdate ? (
                  <>
                    {resettokenExpiry ? (
                      <InvalidPassword styles={styles} setType={setType} />
                    ) : (
                      <>
                        <div className={styles.login_by_mail_id_text}>Set a New Password</div>
                        <div className={styles.pswd_text}>
                          A strong password is at least 8 characters in length and contains letters,
                          numbers, and symbols.
                        </div>
                        {changePasswordAPIErr !== '' ? (
                          <div className={styles.email_error_msg}>{changePasswordAPIErr}</div>
                        ) : (
                          ''
                        )}
                        <input
                          className={styles.email_id}
                          id="password"
                          type="password"
                          placeholder="New Password"
                          maxLength="50"
                          onChange={onChangeNewPassword}                          
                        />
                        {passwordObjErr.newPassword !== '' ? (
                          <div className={styles.email_error_msg}>{passwordObjErr.newPassword}</div>
                        ) : (
                          ''
                        )}
                        <input
                          className={styles.email_id}
                          id="password"
                          type="password"
                          placeholder="Confirm New Password"
                          maxLength="50"
                          onChange={onChangeConfirmPassword}                          
                        />
                        {passwordObjErr.confirmPassword !== '' ? (
                          <div className={styles.email_error_msg}>
                            {passwordObjErr.confirmPassword}
                          </div>
                        ) : (
                          ''
                        )}
                        <button className={styles.btn_login} onClick={onClickConfirmbtn}>
                          Set New Password
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <div>
                    <div className={styles.password_update_text}>Password Updated</div>
                    <div className={styles.password_success_text}>
                      {' '}
                      We successfully changed your password.
                    </div>
                    <div className={styles.password_success_btn}>
                      <button className={styles.btn_password} onClick={BackToLogin}>
                        Back to Login
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* <div className={styles.button_div}>
            <button className={styles.login_btn}onClick={()=> setType("Signin")} >Sign In</button>
            <button className={styles.login_btn}  onClick={()=> setType("Signup")}>Sign Up</button>
            <button className={styles.login_btn} onClick={()=> setType("Demo")}>Request For Demo</button>
            </div> */}
            {type === 'Demo' && (
              <>
                <div className={styles.login_card_container}>
                  <div className={styles.request_info}>
                    <div className={styles.request_detail}>
                      Just fill out the form and our team will get in touch with you shortly
                    </div>
                    <div className={styles.request_title}>With Elevate Trips you can</div>
                    <ul className={styles.request_info_point}>
                      <li>Lorem ipsum is a placeholder text commonly ontent.</li>
                      <li>Lorem ipsum is a placeholder</li>
                      <li>Lorem ipsum is a placeholder text commonly ontent.</li>
                    </ul>
                    <div className={styles.request_title}>
                      Join Elevate Trips and experience Savings, Convenience, Transparency, and
                      Choice in agency travel.
                    </div>
                  </div>
                  <div className={styles.login_card}>
                    <div className={styles.login_form_container}>
                      <input
                        className={styles.fill_input}
                        id="name"
                        autoComplete="off"
                        type="name"
                        placeholder="Name*"
                        maxLength="50"
                      />
                      {/* <input className={styles.fill_input} id="Company name" autoComplete="off" type="company Name" placeholder="Company Name*" maxLength="50" /> */}
                      <div className={styles.country_drp}>
                        <div>
                          {/* <IsdCountryDropdown
                            CountryListOptions={originCountries}
                            selectCountryCode={selectCountryCodeFunctionTraveller}
                            searchText={state.demoForm.countryCode}
                            selectedCountryInfo={state.selectedCountry}
                            onClickInputCountry={onClickInputCountryTraveller}
                            onChangeInputCountryCodeFunction={
                              onChangeInputCountryCodeFunctionTraveller
                            }
                            onKeyDownCountry={onKeyDownCountryCodeTraveller}
                            placeholder={'ISD'}
                            {...ISDstyles}
                          /> */}
                          <IsdCodeDropdown
                            label="ISD Code"
                            placeholder="ISD Code"
                            showFlag={true}
                            showCountryCode={true}
                            showCountryName={true}
                            showISDCode={true}
                            onSelect={(country) => { handleIsdSelect(country) }}
                            styles={IsdCodeDropdownCss}
                            value={state?.demoForm?.countryCode}
                            onClick={onClickIsd}
                            isMobile={props.isMobile}
                            showLabel={props.isMobile ? true : false}
                            ModalCssMobile={ModalCssMobile}
                            autoPopulateOnOutsideClick={false}
                          />
                          {/* {this.state.signupFormErr.countryCode !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.countryCode}</div> : ""} */}
                        </div>
                        <div>
                          <input
                            className={styles.fill_input}
                            type="text"
                            placeholder="Mobile No."
                            maxLength="22"
                            // value={this.state.signupForm.mobileNo} onChange={this.onChangeSignMobile}
                          />
                          {/* {this.state.signupFormErr.mobileNo !== "" ? <div className={styles.email_error_msg}>{this.state.signupFormErr.mobileNo}</div> : ""} */}
                        </div>
                      </div>
                      <input
                        className={styles.fill_input}
                        id="email"
                        autoComplete="off"
                        type="email"
                        placeholder="Email Id*"
                        maxLength="50"
                      />
                      <input
                        className={styles.fill_input}
                        id="city"
                        autoComplete="off"
                        type="city"
                        placeholder="City"
                        maxLength="50"
                      />
                      <input
                        className={styles.fill_input}
                        id="company"
                        autoComplete="off"
                        type="company"
                        placeholder="Company"
                        maxLength="50"
                      />
                      <button
                        className={styles.btn_login}
                        //  onClick={this.onClickLoginBtn}
                      >
                        Request a Demo
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(type === 'Signin' || type === 'Signup' || type === 'Forgotpassword') && (
              <>
                <div
                  className={
                    type === 'Signup'
                      ? `${styles.signup_card_container}`
                      : `${styles.login_card_container}`
                  }
                >
                  <div className={styles.login_card}>
                    <div className={styles.img_div}>
                      <img src={imgLogo} alt="logo" />
                    </div>
                    {renderlogin()}
                  </div>
                  {/* <div class={styles.business_logo}>
                <img src="theme4/login_img.png" alt=""/>
            </div> */}
                </div>
              </>
            )}{' '}
          </>
        )}
      </div>
    </>
  )
}
