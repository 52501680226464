import styles from "./index.module.css";
import cf from '../../../../../common/lib/utils/common';

export default function InvalidPassword(props) {

    const BackToForgotPassword = ()=>{
        // console.log("testttt");
        cf.redirectTo("/login")
        props.setType("Forgotpassword")
    }

    const BackToLogin = ()=>{
        cf.redirectTo("/login")
    }
    return(
    <>
        <div class={styles.invalidmodal_wrap}>
            <div className={styles.brand_logo}>
                <img src="/images/logoele.png" alt="" />
            </div>
            <div className={styles.inv_heading}>Invalid Password Reset Link</div>
            < div className={styles.inv_title}> This link is no longer valid.Please try resetting
            your password again </div>
            <div className={styles.button_wrap}>
                <button className={styles.reset_btn} onClick={() => BackToForgotPassword()}>Reset</button>
                <button className={styles.cancel_btn} onClick={() => BackToLogin()}>Cancel</button>
            </div>
        </div>
    </>
    )

}