import { useEffect, useState } from 'react'
import * as useragent from 'express-useragent'
import Theme1 from '@/components/pages/Login/theme1/index'
import Theme2 from '@/components/pages/Login/theme2/index'
import Theme4 from '@/components/pages/Login/theme4/index'
import Theme5 from '@/components/pages/Login/theme5/index'
import MobileTheme1 from '@/components/pages/Login/theme1/mobindex'
import MobileTheme4 from '@/components/pages/Login/theme4/mobindex'
// import Theme2 from "@/components/pages/abc/theme2";
import Loader from '@/components/loader/index'
import configUrl from '../../../config/config.json'
import cf from '../../../common/lib/utils/common'
import { getHomePageData } from '../../../common/lib/Homepage'
import { useRouter } from 'next/router'

export function Login(props) {
  console.log('props====>', props)
  const theme = props?.pageInfo?.site?.site_theme
  // const theme = "theme4"

  const [isLoading, setIsLoading] = useState(true)
  const router = useRouter()

  switch (theme) {
    case 'theme1':
      return props.isMobile ? (
        <MobileTheme1 {...props} /* sessionData={sessionData} */ />
      ) : (
        <Theme1 {...props} /* sessionData={sessionData} */ />
      )
    case 'theme2':
      return props.isMobile ? (
        <MobileTheme1 {...props} /* sessionData={sessionData} */ />
      ) : (
        <Theme2 {...props} /* sessionData={sessionData} */ />
      )
    case 'theme4':
      return props.isMobile ? (
        <MobileTheme4 {...props} /* sessionData={sessionData} */ />
      ) : (
        <Theme4 {...props} /* sessionData={sessionData} */ />
      )
    case 'theme1':
      return props.isMobile ? (
        <MobileTheme1 {...props} /* sessionData={sessionData} */ />
      ) : (
        <Theme5 {...props} /* sessionData={sessionData} */ />
      )
    default:
      return props.isMobile ? <MobileTheme1 {...props} /> : <Theme1 {...props} />
  }
  // }
}
//server side rendering
export async function getServerSideProps({ req, res, params }) {
  let pageInfo = {}
  let homeData = {}
  try {
    let response = await getHomePageData(req) // home pagedata
    // const res = await fetch(configUrl.BASE_URL + '/api/shared/config/6124e1368ede4f08bf2d9ad4/' + pagepath)
    pageInfo = response
    const resData = response
    if (!cf.isValueEmpty(resData) && resData.status === true && !cf.isValueEmpty(resData.data)) {
      homeData = { status: true, data: resData }
    } else {
      homeData = { status: false, data: {} }
    }
  } catch (error) {
    console.log('error==========', error.message)
  }
  return {
    props: {
      pageInfo: pageInfo,
      isMobile: useragent.parse(req.headers['user-agent']).isMobile,
      home: homeData,
      userToken: req?.cookies?.userToken || '',
    },
  }
}

export default Login
