// import styles from "./index.module.css"
// import Image from 'next/image'
// export default function Theme1(props) {
//     return (
//        <div className={styles.centered}>
//             <Image src="/images/Loader.gif" className={styles.loader}  width="200" height="200" />
//        </div>
//     )

// }

import styles from './index.module.css'
import Image from 'next/image'
import configUrl from '../../../../config/config.json'
import cf from '../../../../common/lib/utils/common'
export default function Theme1(props) {
  let loader
  // console.log(props?.home?.data?.site, 'loader');
  // console.log(props, 'loader');
  if (
    !cf.isValueEmpty(props?.props?.home?.data?.site) &&
    !cf.isValueEmpty(props?.props?.home?.data?.site?.default_loader)
  ) {
    loader =
      '/shared/api/media/' +
      props?.props?.home?.data?.site?.id +
      '/' +
      props?.props?.home?.data?.site?.default_loader
  } else {
    loader = './images/default_loder.gif'
  }

  // console.log('loaderpath', loader)

  let loaderChecker = loader
  // console.log('loader', loaderChecker)
  return (
    <div className={`${styles.loader_main} ${styles.centered} loader-main`}>
       {props?.showmsg && <span>{props.msg}</span>}
      <img src={loaderChecker} className={styles.loader} alt="" />
    </div>
  )
}
